<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="trigger"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="text-center sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Finaliza el estado de la reserva
                </h3>
                <!-- <div class="mt-2">
                  <p class="text-sm text-gray-500 mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Porro atque, voluptas, labore optio ratione fugiat amet,
                    architecto dolores praesentium nesciunt animi et. Nesciunt
                    nisi eos similique vel veniam delectus non!
                  </p>
                </div> -->
                <div class="w-full">
                  <select
                    v-model="state"
                    class="col-span-2 md:col-span-1 mt-2 md:mt-0 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  >
                    <option selected disabled>Selecciona un estado</option>
                    <option
                      v-for="(stateElement, stateIndex) in states"
                      :key="stateIndex"
                      :value="stateElement"
                      >{{ stateElement }}</option
                    >
                  </select>
                </div>
                <div
                  class="w-full"
                  v-if="
                    !user.roles.find(e => e.name === 'psychologist') &&
                      state === 'finalizada'
                  "
                >
                  <!-- extension -->
                  <div
                    class="w-full my-4 flex flex-col justify-center items-start"
                  >
                    <label
                      for="hospitalization"
                      class="block text-sm font-medium text-gray-700"
                      >¿Se extendió licencia?</label
                    >
                    <div class="mt-1 w-full flex">
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              extension = true;
                              extension_null = false;
                            }
                          "
                          type="button"
                          :class="extension ? 'bg-green-600' : 'bg-gray-200'"
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="extension"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">Si</span>
                      </div>
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              extension = false;
                              extension_null = false;
                            }
                          "
                          type="button"
                          :class="
                            !extension && !extension_null
                              ? 'bg-green-600'
                              : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="!extension && !extension_null"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">No</span>
                      </div>
                    </div>
                  </div>
                  <!-- hospitalization -->
                  <div
                    class="w-full my-4 flex flex-col justify-center items-start"
                  >
                    <label
                      for="hospitalization"
                      class="block text-sm font-medium text-gray-700"
                      >¿Se indica hospitalización?</label
                    >
                    <div class="mt-1 w-full flex">
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              hospitalization = true;
                              hospitalization_null = false;
                            }
                          "
                          type="button"
                          :class="
                            hospitalization ? 'bg-green-600' : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="hospitalization"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">Si</span>
                      </div>
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              hospitalization = false;
                              hospitalization_null = false;
                            }
                          "
                          type="button"
                          :class="
                            !hospitalization && !hospitalization_null
                              ? 'bg-green-600'
                              : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="!hospitalization && !hospitalization_null"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">No</span>
                      </div>
                    </div>
                  </div>
                  <!-- prescription -->
                  <div
                    class="w-full my-4 flex flex-col justify-center items-start"
                  >
                    <label
                      for="hospitalization"
                      class="block text-sm font-medium text-gray-700"
                      >¿Se prescribe hospital de día?</label
                    >
                    <div class="mt-1 w-full flex">
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              prescription = true;
                              prescription_null = false;
                            }
                          "
                          type="button"
                          :class="prescription ? 'bg-green-600' : 'bg-gray-200'"
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="prescription"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">Si</span>
                      </div>
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              prescription = false;
                              prescription_null = false;
                            }
                          "
                          type="button"
                          :class="
                            !prescription && !prescription_null
                              ? 'bg-green-600'
                              : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="!prescription && !prescription_null"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">No</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
          >
            <button
              type="button"
              @click="
                () => {
                  state != '' &&
                  state != 'Selecciona un estado' &&
                  !extension_null &&
                  !hospitalization_null &&
                  !prescription_null
                    ? save()
                    : '';
                }
              "
              :class="
                state == '' ||
                state == 'Selecciona un estado' ||
                extension_null ||
                hospitalization_null ||
                prescription_null
                  ? 'cursor-not-allowed disabled bg-gray-200'
                  : 'bg-green-600 hover:bg-green-700'
              "
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            >
              Guardar
            </button>
            <button
              type="button"
              @click="toggle()"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

function initialState() {
  return {
    states: ['finalizada', 'ausente'],
    state: 'Selecciona un estado',
    extension: false,
    hospitalization: false,
    prescription: false,
    extension_null: true,
    hospitalization_null: true,
    prescription_null: true,
  };
}

export default {
  name: 'FinishBlock',
  props: {
    trigger: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return initialState();
  },
  created() {},
  methods: {
    save() {
      // this.toggle();
      this.finish();
    },
    finish() {
      this.$emit('toggle', {
        status: 'save',
        data: {
          finish_type: this.state,
          extension: this.state === 'finalizada' ? this.extension : null,
          hospitalization:
            this.state === 'finalizada' ? this.hospitalization : null,
          prescription: this.state === 'finalizada' ? this.prescription : null,
        },
      });
      Object.assign(this.$data, initialState());
    },
    toggle() {
      this.$emit('toggle', {
        status: 'ok',
      });
      Object.assign(this.$data, initialState());
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
