<template>
  <div class="w-full mx-auto sm:px-6 lg:px-0 lg:grid lg:grid-cols-10 lg:gap-8">
    <modal-create-block-admin
      :doctorprop="doctorprop"
      :trigger="createToogle"
      :date="selectedDateString"
      @toggle="
        event => {
          createToogle = !createToogle;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <modal-finish-block
      :trigger="finishToogle"
      @toggle="
        event => {
          event.status == 'save' ? finishBlock(finishToogle, event.type) : '';
          finishToogle = null;
        }
      "
    />
    <modal-user-block
      :trigger="userBlockToogle"
      @toggle="
        event => {
          userBlockToogle = null;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <modal-create-schedule-admin
      :doctorprop="doctorprop"
      :trigger="scheduleToogle"
      @toggle="
        event => {
          scheduleToogle = !scheduleToogle;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <modal-create-schedule-delete-admin
      :doctorprop="doctorprop"
      :trigger="scheduleDeleteToogle"
      @toggle="
        event => {
          scheduleDeleteToogle = !scheduleDeleteToogle;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <modal-delete-block-admin
      :doctorprop="doctorprop"
      :trigger="deleteToogle"
      @toggle="
        event => {
          deleteToogle = null;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <modal-delete-blocked-block
      :trigger="deleteBlockedToogle"
      @toggle="
        event => {
          deleteBlockedToogle = null;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <modal-delete-block-all-admin
      :doctorprop="doctorprop"
      :trigger="deleteToogleAll"
      @toggle="
        event => {
          deleteToogleAll = null;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <modal-edit-block-admin
      v-if="editBlockToogle !== null"
      :id="editBlockWithUserToogle"
      :trigger="editBlockToogle"
      @toggle="
        event => {
          editBlockToogle = null;
          editBlockWithUserToogle = null;
          event == 'save' ? getBlocks() : '';
        }
      "
    />
    <div v-show="summaryToogle == null" class="col-span-1 md:col-span-4">
      <nav aria-label="Sidebar" class="sticky top-24 divide-y divide-gray-300">
        <div class="pb-8">
          <calendar-component
            :day="day"
            @updateDay="d => (day = d)"
            :month="month"
            @updateMonth="m => (month = m)"
            :year="year"
            @updateYear="y => (year = y)"
            :daysAvailable="daysGray"
          />
          <div
            class="mt-5 w-full border border-dotted border-gray-300 p-5 rounded-md flex flex-col justify-center items-start"
          >
            <span class="w-full mb-2 text-md text-gray-600 font-bold text-left"
              >Editar horario de trabajo</span
            >
            <div class="pl-5 w-full">
              <button
                @click="
                  () => {
                    scheduleToogle = !scheduleToogle;
                  }
                "
                class="text-gray-900 bg-gray-200 mb-2 hover:bg-gray-300 group flex items-center px-3 py-2 text-sm font-medium rounded-md w-full"
              >
                <span class="truncate">Crear horario de trabajo</span>
              </button>
              <button
                @click="
                  () => {
                    !disableCreation ? (createToogle = !createToogle) : '';
                  }
                "
                :disabled="disableCreation"
                :class="
                  disableCreation
                    ? 'cursor-not-allowed text-gray-500'
                    : 'text-gray-900 bg-gray-200 hover:bg-gray-300'
                "
                class="group flex mb-2 items-center px-3 py-2 text-sm font-medium rounded-md w-full"
              >
                <span class="truncate">Agendar sobrecupo</span>
              </button>
              <button
                @click="
                  () => {
                    scheduleDeleteToogle = !scheduleDeleteToogle;
                  }
                "
                class="text-red-600 hover:text-red-800 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
              >
                <span class="truncate"
                  >Borrar multiples bloques del horario</span
                >
              </button>
            </div>
          </div>
          <!-- <div class="mt-5 w-full flex flex-col justify-center items-start">
            <div class="flex flex-row justify-start items-center mb-2">
              <div class="h-5 w-5 rounded-full bg-gray-300 mr-2"></div>
              <span class="text-gray-900 text-sm">Bloque reservado</span>
            </div>
            <div class="flex flex-row justify-start items-center mb-2">
              <div class="h-5 w-5 rounded-full bg-green-500 mr-2"></div>
              <span class="text-gray-900 text-sm"
                >Bloque disponible para reservar</span
              >
            </div>
            <div class="flex flex-row justify-start items-center">
              <div class="h-5 w-5 rounded-full bg-gray-800 mr-2"></div>
              <span class="text-gray-900 text-sm"
                >Bloque de reserva bloqueado</span
              >
            </div>
          </div> -->
        </div>
      </nav>
    </div>
    <div v-show="summaryToogle == null" class="col-span-1 md:col-span-6">
      <div
        v-if="blocks.filter(e => e.id).length === 0"
        class="w-full border border-gray-200 border-dashed rounded-md"
      >
        <section class="max-w-lg px-4 py-20 mx-auto space-y-1 text-center">
          <img class="mx-auto w-20" src="@/assets/img/icons/open-box.png" />
          <h2 class="text-lg font-medium text-gray-800">
            Sin horas reservadas
          </h2>
          <!-- <p class="text-gray-600">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod maxime
            maiores consectetur necessitatibus animi ea veniam optio eos! Id
            animi et excepturi earum aliquid deleniti.
          </p> -->
        </section>
      </div>
      <div v-if="blocks.length !== 0" class="mb-4">
        <div
          v-for="(blockElement, blockIndex) in blocks"
          :key="blockIndex"
          class="border border-gray-200 overflow-hidden rounded-md bg-white mb-3 grid grid-cols-12"
        >
          <div
            class="bg-gray-200 col-span-2 border-r-4 border-dashed border-gray-300 flex flex-col justify-center items-center"
          >
            <span
              v-if="blockElement.id"
              class="text-sm font-semibold mb-2"
              :class="
                blockElement.is_not_available && !blockElement.is_blocked
                  ? 'bg-gray-300 px-2 py-1 rounded-md'
                  : blockElement.is_blocked
                  ? 'bg-gray-800 px-2 py-1 rounded-md text-white'
                  : 'bg-green-500 px-2 py-1 rounded-md text-white'
              "
              >{{ formatToHour(blockElement.start_date) }}</span
            >
            <span
              v-if="!blockElement.id"
              class="text-sm font-semibold mb-2 mt-3 bg-gray-300 text-gray-900 px-2 py-1 rounded-md"
              >{{ formatToHour(blockElement.start_date) }}</span
            >
            <span
              v-if="!blockElement.id"
              class="text-sm font-semibold mb-3 bg-gray-300 text-gray-900 px-2 py-1 rounded-md"
              >{{ formatToHour(blockElement.finish_date) }}</span
            >
            <!-- <span class="text-sm font-semibold">{{
            formatToHour(blockElement.finish_date)
          }}</span> -->
            <!-- No reservation and block rate -->
            <span
              class="text-xs font-semibold"
              v-if="
                blockElement.id &&
                  blockElement.rate &&
                  !blockElement.reservation
              "
            >
              {{
                chileanPesos(
                  blockCalculationPrice(blockElement, blockElement.rate)
                )
              }}</span
            >
            <!-- No reservation and No block rate, but Doctor rate -->
            <span
              class="text-xs font-semibold"
              v-if="
                blockElement.id &&
                  !blockElement.rate &&
                  !blockElement.reservation &&
                  doctor &&
                  doctor.doctor
              "
              >{{
                chileanPesos(
                  blockCalculationPrice(blockElement, doctor.doctor.rate)
                )
              }}</span
            >
            <span
              class="text-xs font-semibold"
              v-if="
                blockElement.id &&
                  !blockElement.rate &&
                  !blockElement.reservation &&
                  doctor &&
                  doctor.psychologist
              "
              >{{
                chileanPesos(
                  blockCalculationPrice(blockElement, doctor.psychologist.rate)
                )
              }}</span
            >
            <span
              class="text-xs font-semibold"
              v-if="
                blockElement.id &&
                  !blockElement.rate &&
                  !blockElement.reservation &&
                  doctor &&
                  doctor.superdoctor
              "
              >{{
                chileanPesos(
                  blockCalculationPrice(blockElement, doctor.superdoctor.rate)
                )
              }}</span
            >
            <!-- It has reservation already -->
            <span
              class="text-xs font-semibold"
              v-if="blockElement.id && blockElement.reservation"
            >
              {{ chileanPesos(blockElement.reservation.purchase.value) }}</span
            >
          </div>
          <div
            v-if="!blockElement.id"
            class="col-span-10 border-r border-dashed border-gray-300 bg-gray-200 flex flex-col justify-center items-center"
          >
            <span class="text-sm font-semibold py-5">Hora disponible</span>
          </div>
          <div
            v-if="blockElement.id"
            class="col-span-7 px-3 py-2 flex flex-col justify-start items-start"
          >
            <span
              class="text-sm text-gray-500 text-left font-semibold"
              v-if="blockElement.is_not_available || blockElement.is_blocked"
              ><span
                v-if="blockElement.is_not_available && !blockElement.is_blocked"
                >Reservada</span
              ></span
            >
            <span class="text-md text-gray-500 text-left font-semibold" v-else
              >Disponible</span
            >
            <span
              class="text-md text-gray-900 text-left font-bold"
              v-if="blockElement.text"
              >{{ blockElement.text }}</span
            >
            <!-- <span class="text-md text-gray-900 text-left font-normal" v-else
              >Sin texto</span
            > -->
            <div
              class="bg-gray-50 flex flex-row justify-start items-center border border-gray-200 px-2 py-1 text-gray-800 text-md rounded-md"
              v-if="blockElement.reservation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 mr-2 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
              <div class="w-full text-left text-green-700">
                {{ capitalize(blockElement.reservation.purchase.user.names) }}
                {{
                  capitalize(blockElement.reservation.purchase.user.lastnames)
                }}
              </div>
            </div>
            <button
              class="text-sm flex flex-row text-gray-500 text-left font-semibold pt-2"
              v-if="blockElement.is_finish && blockElement.is_not_available"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Estado: {{ blockElement.finish_type }}</span>
            </button>
          </div>
          <div
            v-if="blockElement.id"
            class="col-span-3 border-l border-dashed border-gray-200 px-2 py-2 flex flex-col justify-start items-center"
          >
            <button
              class="mb-2 bg-gray-200 w-full flex flex-row-reverse justify-between items-center hover:bg-gray-300 text-gray-700 rounded-md text-sm px-2 py-1"
              v-if="!blockElement.is_not_available"
              @click.prevent="
                () => {
                  userBlockToogle = blockElement.id.toString();
                }
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                />
              </svg>
              <span>reservar</span>
            </button>
            <button
              class="mb-2 bg-gray-200 w-full flex flex-row-reverse justify-between items-center hover:bg-gray-300 text-gray-700 font-bold rounded-md text-sm px-2 py-1"
              v-if="blockElement.is_not_available"
              @click="
                () => {
                  editBlockToogle = blockElement.id.toString();
                  editBlockWithUserToogle = blockElement.user.id.toString();
                }
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                />
                <path
                  fill-rule="evenodd"
                  d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>editar</span>
            </button>
            <!-- <button
              class="mb-2 bg-gray-200 w-full flex flex-row-reverse justify-between items-center hover:bg-gray-300 text-gray-700 font-bold rounded-md text-sm px-2 py-1"
              v-if="
                blockElement.session &&
                  !blockElement.session.is_activated &&
                  !blockElement.session.is_finish &&
                  blockElement.is_not_available
              "
              @click="
                () => {
                  activateSession(blockElement);
                }
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                <path
                  fill-rule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>abrir atención</span>
            </button> -->
            <button
              class="cursor-not-allowed mb-2 bg-green-500 w-full flex flex-row-reverse justify-center items-center text-white font-bold rounded-md text-sm px-2 py-1"
              v-if="blockElement.session && blockElement.session.is_activated"
            >
              <span>atención abierta</span>
            </button>
            <button
              v-if="
                blockElement.is_blocked &&
                  blockElement.reservation &&
                  (!blockElement.session ||
                    (blockElement.session &&
                      !blockElement.session.is_activated))
              "
              @click="
                () => {
                  blockElement.is_blocked
                    ? deleteBlockedBlock(blockElement)
                    : '';
                }
              "
              class="mb-2 bg-red-50 w-full flex flex-row-reverse justify-between items-center hover:bg-red-100 text-red-700 rounded-md text-sm px-2 py-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>eliminar</span>
            </button>
            <button
              v-if="!blockElement.reservation"
              @click="
                () => {
                  !blockElement.reservation ? deleteBlock(blockElement) : '';
                }
              "
              class="mb-2 bg-red-50 w-full flex flex-row-reverse justify-between items-center hover:bg-red-100 text-red-700 rounded-md text-sm px-2 py-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>eliminar</span>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="blocks.filter(e => e.id).length !== 0"
        class="flex flex-row w-full justify-end items-center"
      >
        <button
          @click="
            () => {
              !disableCreation ? deleteBlockAll() : '';
            }
          "
          :disabled="disableCreation"
          :class="
            disableCreation
              ? 'cursor-not-allowed text-red-300'
              : 'text-red-600 bg-red-100 hover:text-red-800'
          "
          class="group flex items-center px-3 py-2 text-sm font-medium rounded-md"
        >
          <span class="truncate">Bloquear agenda día completo</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import CreateBlockAdmin from '../doctor/calendar/createAdmin.vue';
import FinishBlock from '../doctor/calendar/finish.vue';
import CreateScheduleAdmin from '../doctor/calendar/scheduleAdmin.vue';
import Calendar from '@/components/app/Calendar';
import DeleteScheduleAdmin from '../doctor/calendar/deleteScheduleAdmin.vue';
import DeleteBlockAdmin from '../doctor/calendar/deleteAdmin.vue';
import DeleteBlockedBlock from '../doctor/calendar/deleteBlocked.vue';
import DeleteBlockAllAdmin from '../doctor/calendar/deleteallAdmin.vue';
import BlockService from '@/services/blockService';
import BlockUser from '../doctor/calendar/blockUser.vue';
import EditBlockAdmin from '../doctor/calendar/edit.vue';
import DoctorService from '@/services/doctorService';
import Swal from 'sweetalert2';

export default {
  name: 'Calendar',
  components: {
    'calendar-component': Calendar,
    'modal-create-block-admin': CreateBlockAdmin,
    'modal-finish-block': FinishBlock,
    'modal-create-schedule-admin': CreateScheduleAdmin,
    'modal-create-schedule-delete-admin': DeleteScheduleAdmin,
    'modal-delete-block-admin': DeleteBlockAdmin,
    'modal-delete-blocked-block': DeleteBlockedBlock,
    'modal-delete-block-all-admin': DeleteBlockAllAdmin,
    'modal-edit-block-admin': EditBlockAdmin,
    'modal-user-block': BlockUser,
  },
  data() {
    return {
      month: moment().month(),
      year: moment().year(),
      day: moment().date(),
      blocks: [],
      pool: [],
      daysGray: [],
      createToogle: false,
      scheduleToogle: false,
      scheduleDeleteToogle: false,
      deleteToogle: null,
      finishToogle: null,
      deleteToogleAll: null,
      deleteBlockedToogle: null,
      editBlockToogle: null,
      editBlockWithUserToogle: null,
      summaryToogle: null,
      summaryToogleBlock: null,
      doctor: null,
      userBlockToogle: null,
    };
  },
  created() {
    this.getBlocks();
    this.doctor = this.doctorprop;
    this.getDaysGray();
  },
  methods: {
    capitalize(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    },
    finishBlock(id, type) {
      BlockService.finishBlock(id, type)
        .then(() => {
          this.getBlocks();
        })
        .catch(() => {
          console.log('error finishing this block');
        });
    },
    blockCalculationPrice(block, rate) {
      let value = 0;
      if (block.full_rate) {
        return Math.floor(block.full_rate);
      } else if (rate) {
        let duration = moment.duration(
          moment(
            block.finish_date + ' ' + block.finish_date,
            'YYYY-MM-DD H:mm'
          ).diff(
            moment(block.start_date + ' ' + block.start_time, 'YYYY-MM-DD H:mm')
          )
        );
        return Math.floor(duration.asHours() * rate);
      }
      return value;
    },
    chileanPesos(value) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(value).replace(/,/g, '.');
    },
    getBlocks() {
      this.blocks = [];
      let date =
        (this.day <= 9 ? '0' : '') +
        this.day +
        '/' +
        (this.month + 1 <= 9 ? '0' : '') +
        (this.month + 1) +
        '/' +
        this.year;
      BlockService.getByDateAdmin(this.doctorprop.id, date)
        .then(response => {
          let blocks = response.data.blocks;
          let output = [];
          let selectedDate = moment(
            (this.day > 9 ? '' : '0') +
              this.day +
              '-' +
              (this.month + 1 > 9 ? '' : '0') +
              (this.month + 1) +
              '-' +
              this.year,
            'DD-MM-YYYY'
          );
          let hour_counter = 0;
          let minutes_counter = 0;
          blocks.forEach(e => {
            let start_date = moment(e.start_date, 'YYYY-MM-DD H:mm');
            let finish_date = moment(e.finish_date, 'YYYY-MM-DD H:mm');
            output.push({
              start_date: selectedDate
                .clone()
                .set('hour', hour_counter)
                .set('minute', minutes_counter),
              finish_date: selectedDate
                .clone()
                .set('hour', start_date.hour())
                .set('minute', start_date.minutes()),
            });
            hour_counter = finish_date.hour();
            minutes_counter = finish_date.minutes();
            output.push(e);
          });
          output.push({
            start_date: selectedDate
              .clone()
              .set('hour', hour_counter)
              .set('minute', minutes_counter),
            finish_date: selectedDate
              .clone()
              .set('hour', 23)
              .set('minute', 59),
          });
          output = output.filter(e => {
            let start_date = moment(e.start_date, 'YYYY-MM-DD H:mm');
            let finish_date = moment(e.finish_date, 'YYYY-MM-DD H:mm');
            return e.id || start_date.diff(finish_date) != 0;
          });
          // remove first and last if they are empty
          if (output.length != 0) {
            if (!output[0].id) {
              output = output.filter((_, index) => index != 0);
            }
          }
          if (output.length != 0) {
            if (!output[output.length - 1].id) {
              output = output.filter((_, index) => index != output.length - 1);
            }
          }
          this.blocks = output;
        })
        .catch(error => {
          console.log(error);
        });
    },
    formatToHour(date) {
      return moment(date, 'YYYY-MM-DD H:mm').format('HH:mm');
    },
    deleteBlock(block) {
      this.deleteToogle = block.id.toString();
    },
    activateSession(block) {
      Swal.fire({
        iconHtml: '',
        title: '¿Estás seguro?',
        confirmButtonColor: '#13b981',
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No',
        customClass: {
          confirmButton:
            'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-green-600 hover:bg-green-700',
          cancelButton:
            'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-green-600 hover:bg-green-700',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOut',
        },
      }).then(result => {
        if (result.isConfirmed) {
          BlockService.activateBlock(block.id)
            .then(() => {
              this.getBlocks();
            })
            .catch(() => {
              console.log('error activating this block');
            });
        }
      });
    },
    deleteBlockedBlock(block) {
      this.deleteBlockedToogle = block.id.toString();
    },
    deleteBlockAll() {
      this.deleteToogleAll = this.blocks.filter(e => !e.reservation && e.id);
    },
    months() {
      moment.locale('es');
      return moment.months();
    },
    years() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - parseInt(back / 2) + i + 1
        );
      };

      return years(10);
    },
    getDaysGray() {
      DoctorService.getCalendarGrayDays({
        doctor: this.doctor.id,
        month: this.month + 1,
        year: this.year,
      })
        .then(response => {
          this.daysGray = response.data;
        })
        .catch(() => {
          console.log('error getting days data');
        });
    },
  },
  watch: {
    day() {
      // every time day changes I get all block time sorted
      this.getBlocks();
    },
    month() {
      this.day = 1;
      this.getDaysGray();
    },
    year() {
      this.day = 1;
      this.getDaysGray();
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
      doctorprop: ['admin/user'],
    }),
    selectedDateString() {
      let selectedDate = moment(
        (this.day > 9 ? '' : '0') +
          this.day +
          '-' +
          (this.month + 1 > 9 ? '' : '0') +
          (this.month + 1) +
          '-' +
          this.year,
        'DD-MM-YYYY'
      );
      return selectedDate.format('YYYY-MM-DD');
    },
    disableCreation() {
      let selectedDate = moment(
        (this.day > 9 ? '' : '0') +
          this.day +
          '-' +
          (this.month + 1 > 9 ? '' : '0') +
          (this.month + 1) +
          '-' +
          this.year,
        'DD-MM-YYYY'
      );
      return selectedDate.diff(moment().startOf('day')) < 0;
    },
    dayOfWeek() {
      return moment(
        this.year + '-' + (parseInt(this.month) + 1) + '-01',
        'YYYY-MM-DD'
      ).isoWeekday();
    },
    days() {
      return moment(
        this.year + '-' + (parseInt(this.month) + 1),
        'YYYY-MM'
      ).daysInMonth();
    },
  },
};
</script>

<style scoped></style>
